import { CreditNoteConstant, creditNoteHdrRequiredFieldList } from "presentation/constant/CreditNote/CreditNoteConstant";
import { useCreditNoteDetailVM } from "presentation/hook/CreditNote/useCreditNoteDetailVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCreditNoteDetailTracked } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useEffect, useMemo } from "react";
import { FieldType, HPHInputMask, IFieldValue, IconButton } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, SidebarTitle, Sidebarheader, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const CreditNoteHeaderFieldFormPanel = () => {

    const [ creditNoteDetailState ] = useCreditNoteDetailTracked();
    const creditNoteDetailVM = useCreditNoteDetailVM();
    const { isSaveClicked,allFormState,isAdd,isEditable,isRead,editingHeader,isShowDetailInfo,isShowInvoicePanel} = creditNoteDetailState.creditNoteDetailState;
    const {creditNoteDetails} = creditNoteDetailState;
    const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Header;

    const currentCreditNoteHdr = isRead? creditNoteDetailState.currentCreditNoteHdr : editingHeader;

    const [anaInfoState] = useANAInfoTracked();
    const {allowUpdate} = anaInfoState;

    const isEditDisable = () => {
        if (!(currentCreditNoteHdr.hdrState === "NIL" && creditNoteDetails.length>0 && creditNoteDetails.every(entity => entity.adjType === "Normal"))) return true;

        return false;
    }

    const isDisable = () => {
        if (!((isAdd && creditNoteDetails.length>0 && !isShowDetailInfo && !isShowInvoicePanel) || (isEditable && !isRead && creditNoteDetails.length>0 && !isShowDetailInfo && !isShowInvoicePanel))) return true;
        // if (!isRead && creditNoteDetails.length===0 && isShowDetailInfo) return true;

        return false;
    }

    // const handleClose = useCallback(() => {
    //     if(isRead || !editingHeader.id || editingHeader.id === 0){
    //         creditNoteDetailVM.onCloseClick();
    //     }else{
    //         creditNoteDetailVM.onShowLoading();
    //         creditNoteDetailVM.onTitleBarCloseClick();
    //         creditNoteDetailVM.onSearch(editingHeader).then(()=>{
    //             creditNoteDetailVM.onHideLoading();
    //         }).catch((error) => {     
    //             creditNoteDetailVM.onHideLoading();
    //         })
    //     }
    // }, [editingHeader, creditNoteDetailVM, isRead]);

    const handleEdit = useCallback(() => {
        creditNoteDetailVM.onHeaderEdit(currentCreditNoteHdr);
    }, [creditNoteDetailVM, currentCreditNoteHdr]);

    const handleSave = useCallback(async () => {
        if(creditNoteDetails.length > 0){
            creditNoteDetailVM.onShowLoading();
            await creditNoteDetailVM.onTitleSaveClick(currentCreditNoteHdr,creditNoteDetails).then((data) =>{
                // creditNoteDetailVM.onApply(creditNoteDetails);
                creditNoteDetailVM.onHideLoading();
            }).catch((error) => {
                creditNoteDetailVM.onHideLoading();
            })
        }
    }, [creditNoteDetailVM, creditNoteDetails, currentCreditNoteHdr]);

useEffect(() => {
        const refreshReqNo = async()=>{

            if(currentCreditNoteHdr?.currencyCode){
                creditNoteDetailVM.onRefreshReqNo(currentCreditNoteHdr?.currencyCode);
            }
        }        
        refreshReqNo();
    },[currentCreditNoteHdr?.currencyCode, creditNoteDetailVM])
 
    useEffect(() => {
        const refreshCustomerCode = async()=>{

            if(currentCreditNoteHdr.billToCompany){
                creditNoteDetailVM.initCustomerCodeByBillTo(currentCreditNoteHdr.billToCompany);
            }else{
                creditNoteDetailVM.initCustomerCode();
            }
        }        
        refreshCustomerCode();
    },[creditNoteDetailVM, currentCreditNoteHdr.billToCompany])

    const onNumberFieldChange = useCallback((e:any, fieldName:string, fieldValue:any) => {
        creditNoteDetailVM.onFieldChange(fieldName, fieldValue);
    }, [creditNoteDetailVM]);
    
    const memoState = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.hdrState || ''}
                fieldValue={currentCreditNoteHdr?.hdrState}
                fieldLabel={CREDIT_NOTE_CONSTANT.STATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'hdrState'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />          
        </div>
    , [CREDIT_NOTE_CONSTANT.STATE, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.hdrState, isSaveClicked])

    
    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.chargeType || ''}
                fieldValue={currentCreditNoteHdr?.chargeType}
                fieldLabel={CREDIT_NOTE_CONSTANT.CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'chargeType'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />               
        </div>
    , [CREDIT_NOTE_CONSTANT.CHARGE_TYPE, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.chargeType, isSaveClicked])

    const memoEtd = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <DatePickerComponent
            disabled={isRead}
            label={CREDIT_NOTE_CONSTANT.DEPATURE_DATETIME}
            width="180px"
            date={currentCreditNoteHdr?.etd}
            fieldName="etd"
            errorMessage={allFormState?allFormState["validateRangeDate"]:""}
            onDateChange={creditNoteDetailVM.onHeaderFieldChange}/>
        </div>
    , [CREDIT_NOTE_CONSTANT.DEPATURE_DATETIME, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.etd, isRead])

    const memoCoVslVoy = useMemo(() =>
        <div className='im-flex-row-item' >
            <HPHInputMask
                label={CreditNoteConstant.Search.CO_VSL_VOY}
                width={'390px'}          
                disabled={isRead}
                value={{
                    co: currentCreditNoteHdr?.consortiumCode ?? '',
                    vsl: currentCreditNoteHdr?.vesselCode ?? '',
                    voy: currentCreditNoteHdr?.voyageCode ?? ''
                }}
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={(e: any) => creditNoteDetailVM.onCoVslVoyChange(e, {
                    co: 'consortiumCode',
                    vsl: 'vesselCode',
                    voy: 'voyageCode'
                })}/>
        </div>
    , [creditNoteDetailVM, currentCreditNoteHdr?.consortiumCode, currentCreditNoteHdr?.vesselCode, currentCreditNoteHdr?.voyageCode, isRead])

    const memoVesselName = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.vesselName || ''}
                fieldValue={currentCreditNoteHdr?.vesselName}
                fieldLabel={CREDIT_NOTE_CONSTANT.VESSEL_NAME}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vesselName'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [CREDIT_NOTE_CONSTANT.VESSEL_NAME, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.vesselName, isRead, isSaveClicked])
    
    const memoHandlingTerminal = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.handlingTerminal || ''}
                fieldValue={currentCreditNoteHdr?.handlingTerminal}
                fieldLabel={CREDIT_NOTE_CONSTANT.HANDING_TML}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'yourRef'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [CREDIT_NOTE_CONSTANT.HANDING_TML, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.handlingTerminal, isSaveClicked])

    const memoYourRef = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.yourRef || ''}
                fieldValue={currentCreditNoteHdr?.yourRef}
                fieldLabel={CREDIT_NOTE_CONSTANT.YOUR_REF}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'yourRef'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [CREDIT_NOTE_CONSTANT.YOUR_REF, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.yourRef, isRead, isSaveClicked])

    const memoOurRef = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.ourRef || ''}
                fieldValue={currentCreditNoteHdr?.ourRef}
                fieldLabel={CREDIT_NOTE_CONSTANT.OUR_REF}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'ourRef'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [CREDIT_NOTE_CONSTANT.OUR_REF, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.ourRef, isRead, isSaveClicked])

    const memoOperatingTml = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
            errorMessages={allFormState}
            isReadOnly={isRead}
            isShowOptional={true}
            readOnlyValue={currentCreditNoteHdr?.operatingTml || ''}
            fieldValue={currentCreditNoteHdr?.operatingTml}
            fieldLabel={CREDIT_NOTE_CONSTANT.OPERATING_TML}
            isSaveClicked={isSaveClicked}
            fieldType={FieldType.DROPDOWN}
            fieldKey={'operatingTml'}
            options={creditNoteDetailState.dynamicOptions.operatingTmlDropdownOptions}
            maxLength={60}
            requiredFieldList={creditNoteHdrRequiredFieldList}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [CREDIT_NOTE_CONSTANT.OPERATING_TML, allFormState, creditNoteDetailVM, creditNoteDetailState.dynamicOptions.operatingTmlDropdownOptions, currentCreditNoteHdr?.operatingTml, isRead, isSaveClicked])

    const memoReqNo = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
            errorMessages={allFormState}
            isReadOnly={isRead}
            isShowOptional={true}
            readOnlyValue={currentCreditNoteHdr?.reqNo || ''}
            fieldValue={currentCreditNoteHdr?.reqNo}
            fieldLabel={CREDIT_NOTE_CONSTANT.REQ_NO}
            isSaveClicked={isSaveClicked}
            fieldType={FieldType.DROPDOWN}
            fieldKey={'reqNo'}
            options={creditNoteDetailState.dynamicOptions.reqNoDropdownOptions}
            maxLength={60}
            requiredFieldList={creditNoteHdrRequiredFieldList}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [CREDIT_NOTE_CONSTANT.REQ_NO, allFormState, creditNoteDetailVM, creditNoteDetailState.dynamicOptions.reqNoDropdownOptions, currentCreditNoteHdr?.reqNo, isRead, isSaveClicked])

    const memoHdrDesc1 = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.hdrDesc1 || ''}
                fieldValue={currentCreditNoteHdr?.hdrDesc1}
                fieldLabel={CREDIT_NOTE_CONSTANT.HDR_DESC1}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'hdrDesc1'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />          
    </div>
    , [CREDIT_NOTE_CONSTANT.HDR_DESC1, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.hdrDesc1, isRead, isSaveClicked])

    const memoHdrDesc2 = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.hdrDesc2 || ''}
                fieldValue={currentCreditNoteHdr?.hdrDesc2}
                fieldLabel={CREDIT_NOTE_CONSTANT.HDR_DESC2}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'hdrDesc2'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />      
    </div>
    , [CREDIT_NOTE_CONSTANT.HDR_DESC2, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.hdrDesc2, isRead, isSaveClicked])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.billToCompany || ''}
                fieldValue={currentCreditNoteHdr?.billToCompany}
                fieldLabel={CREDIT_NOTE_CONSTANT.BILL_TO_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                options={creditNoteDetailState.dynamicOptions.billToCompDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />    
        </div>
    , [CREDIT_NOTE_CONSTANT.BILL_TO_COMPANY, allFormState, creditNoteDetailState.dynamicOptions.billToCompDropdownOptions, creditNoteDetailVM, currentCreditNoteHdr?.billToCompany, isRead, isSaveClicked])

    const memoCurrencyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.currencyCode || ''}
                fieldValue={currentCreditNoteHdr?.currencyCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.CURRENCY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'currencyCode'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                options={creditNoteDetailState.dynamicOptions.currencyCodeDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />    
        </div>
    , [CREDIT_NOTE_CONSTANT.CURRENCY_CODE, allFormState, creditNoteDetailVM, creditNoteDetailState.dynamicOptions.currencyCodeDropdownOptions, currentCreditNoteHdr?.currencyCode, isRead, isSaveClicked])

    const memoCustomerCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.customerCode || ''}
                fieldValue={currentCreditNoteHdr?.customerCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.CUSTOMER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'customerCode'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                options={creditNoteDetailState.dynamicOptions.customerCodeDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />    
        </div>
    , [CREDIT_NOTE_CONSTANT.CUSTOMER_CODE, allFormState, creditNoteDetailVM, creditNoteDetailState.dynamicOptions.customerCodeDropdownOptions, currentCreditNoteHdr?.customerCode, isRead, isSaveClicked])

    const memoPoNo = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.poNo || ''}
                fieldValue={currentCreditNoteHdr?.poNo}
                fieldLabel={CREDIT_NOTE_CONSTANT.PO_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'poNo'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />   
    </div>
    , [CREDIT_NOTE_CONSTANT.PO_NO, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.poNo, isRead, isSaveClicked])

    const memoSlVesselCode = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.slVesselCode || ''}
                fieldValue={currentCreditNoteHdr?.slVesselCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.SL_VESSEL_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'slVesselCode'}
                maxLength={6}
                isTextFieldCapitalize={true}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />  
    </div>
    , [CREDIT_NOTE_CONSTANT.SL_VESSEL_CODE, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.slVesselCode, isRead, isSaveClicked])

    const memoSlIbVoyageCode = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.slIbVoyageCode || ''}
                fieldValue={currentCreditNoteHdr?.slIbVoyageCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.SL_IB_VOYAGE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'slIbVoyageCode'}
                maxLength={10}
                isTextFieldCapitalize={true}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />  
    </div>
    , [CREDIT_NOTE_CONSTANT.SL_IB_VOYAGE_CODE, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.slIbVoyageCode, isRead, isSaveClicked])

    const memoSlObVoyageCode = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
        <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.slObVoyageCode || ''}
                fieldValue={currentCreditNoteHdr?.slObVoyageCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.SL_OB_VOYAGE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'slObVoyageCode'}
                maxLength={10}
                isTextFieldCapitalize={true}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />  
    </div>
    , [CREDIT_NOTE_CONSTANT.SL_OB_VOYAGE_CODE, allFormState, creditNoteDetailVM, currentCreditNoteHdr?.slObVoyageCode, isRead, isSaveClicked])

    const memoTotalAmount = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={CREDIT_NOTE_CONSTANT.TOTAL_AMOUNT}
                maxLength={60}
                fieldName={"totalAmount"}
                value={currentCreditNoteHdr?.totalAmount || ''}
                errorMessage={allFormState?.totalAmount}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) =>onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteHdr?.totalAmount?.toString() || ''}
                fieldValue={currentCreditNoteHdr?.totalAmount}
                fieldLabel={CREDIT_NOTE_CONSTANT.TOTAL_AMOUNT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'totalAmount'}
                maxLength={60}
                requiredFieldList={creditNoteHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}          
    </div>
    , [CREDIT_NOTE_CONSTANT.TOTAL_AMOUNT, allFormState, currentCreditNoteHdr?.totalAmount, onNumberFieldChange])

    // const memoHdrForm = useMemo(()=>{return <CreditNoteHeaderFieldForm/>},[])

    return <>
            <div className='side-form-content-left-wrapper'>
                <div className={'flex-row'}>
                    <div className={'flex-row-item flex-row-item-full-width'}>
                        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
                            <Sidebarheader style={{width: '100%'}}>
                                <SidebarTitle>{currentCreditNoteHdr.creditChargeNo??"HEADER"}</SidebarTitle>
                                <SidebarActionCross>
                                    {(!isAdd && (isEditable && isRead)) &&
                                        <>
                                            {
                                                (
                                                    (isEditable && allowUpdate)
                                                )
                                                && <IconButton fileName='Icon-pen' size='medium' disabled={isEditDisable()} toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                                            }
                                        </>}
                                        {/*<IconButton fileName="Icon-cross" size="medium" disabled={!isRead && isShowDetailInfo} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={handleClose} />*/}
                                    {((isAdd || (isEditable && !isRead))) &&
                                        <>
                                        {/* <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} /> */}
                                            {
                                                (
                                                    (isAdd) ||
                                                    (isEditable && !isRead)
                                                )
                                                && <IconButton fileName="Icon-tick" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                                            }
                                        </>}
                                </SidebarActionCross>

                            </Sidebarheader>

                        </StyledSidebar>
                    </div>
                </div>

                {/* {memoHdrForm} */}

                <div className={'add-edit-form'} style={{ height:'78vh', maxHeight:'78vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                    <CriteriaItemContainer>
                        {memoState}{memoChargeType}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoReqNo}{memoEtd}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoCoVslVoy}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoVesselName}
                        {memoHandlingTerminal}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoCurrencyCode}{memoCustomerCode}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoBillToCompany}{memoPoNo}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoOperatingTml}{memoTotalAmount}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoYourRef}{memoOurRef}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoHdrDesc1}{memoHdrDesc2}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoSlIbVoyageCode}
                        {memoSlObVoyageCode}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoSlVesselCode}
                    </CriteriaItemContainer>
                    </div>                  
                </div>
            </div>   

            </div>
        </>
}